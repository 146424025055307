export const profileDescriptionMap = {
    CS: "Consumer/Homeowner",
    CT: "Contractor",
    DS: "Interior Designer",
    DE: "Dealer",
    AR: "Architect",
    OT: "Other",
} as const;

export type ProfileDescription = keyof typeof profileDescriptionMap;

export type ProfileDescriptionValue =
    (typeof profileDescriptionMap)[ProfileDescription];

export interface UserProfile {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    description: ProfileDescription;
    corporate_account?: { account_number: string; party_name: string };
}
