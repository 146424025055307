import { VisuallyHidden } from "@reach/visually-hidden";
import classNames from "classnames";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import {
    NextSlideButton,
    PreviousSlideButton,
} from "components/GenericCarousel";
import { HoverZoom } from "components/HoverZoom";
import { RichTextContent } from "components/RichText";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import { useIsClient } from "helpers/utils";
import { GallerySlide, StreamfieldBlock } from "interfaces";
import {
    CarouselProvider,
    Dot,
    DotGroup,
    Slide,
    Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import styles from "./full-width-carousel.module.scss";

export type FullWidthCarouselCmsData = {
    gallery: Array<StreamfieldBlock<"slide", GallerySlide>>;
};

export type FullWidthCarouselBlock = StreamfieldBlock<
    "full_width_carousel",
    FullWidthCarouselCmsData
>;

export function FullWidthCarousel(props: FullWidthCarouselCmsData) {
    const slides = props.gallery;
    const firstSlide = Math.floor(props.gallery.length / 2);

    const isClient = useIsClient();
    const width = useBlockWidth();

    if (!isClient) {
        return null;
    }

    return (
        <section className={styles.fullWidthCarousel}>
            <div
                className={classNames({
                    [styles.overflowingWithCarousels]: true,
                    [styles.full]: width === "full",
                })}
            >
                <CarouselProvider
                    currentSlide={firstSlide}
                    naturalSlideHeight={479}
                    naturalSlideWidth={767}
                    infinite={false}
                    isIntrinsicHeight={true}
                    totalSlides={slides.length}
                    visibleSlides={1}
                >
                    {props.gallery.length > 1 && (
                        <>
                            <PreviousSlideButton className={styles.previous} />
                            <NextSlideButton className={styles.next} />
                            <DotGroup
                                className={styles.dotGroup}
                                renderDots={({
                                    currentSlide,
                                    totalSlides,
                                    visibleSlides,
                                    disableActiveDots,
                                }) => {
                                    if (
                                        totalSlides !== undefined &&
                                        currentSlide !== undefined &&
                                        visibleSlides !== undefined
                                    ) {
                                        return slides.map((slide, i) => {
                                            const selected = i === currentSlide;
                                            return (
                                                <Dot
                                                    key={slide.id}
                                                    slide={i}
                                                    disabled={
                                                        disableActiveDots
                                                            ? selected
                                                            : false
                                                    }
                                                    className={styles.dot}
                                                >
                                                    <>
                                                        <CmsResponsiveImage
                                                            cmsImage={
                                                                slide.value
                                                                    .image
                                                            }
                                                            sizes="100vw"
                                                            cldSrcSet={[200]}
                                                            cloudinaryProps={{
                                                                aspectRatio:
                                                                    "1:1",
                                                                crop: "fill",
                                                                width: "100",
                                                            }}
                                                            aria-hidden={true}
                                                        />
                                                        <VisuallyHidden>
                                                            Slide {i}
                                                        </VisuallyHidden>
                                                    </>
                                                </Dot>
                                            );
                                        });
                                    }
                                }}
                            />
                        </>
                    )}
                    <Slider trayTag="ul">
                        {slides.map((slide, index) => (
                            <Slide
                                key={slide.id}
                                index={index}
                                className={styles.slide}
                                tag="li"
                            >
                                <figure>
                                    <HoverZoom>
                                        {/* Eager loading because of https://thelabnyc.plan.io/issues/21949 */}
                                        <CmsResponsiveImage
                                            cmsImage={slide.value.image}
                                            sizes="(max-width: 767px) 80vw, (min-width: 767) 60vw"
                                            cldSrcSet={[600, 1200]}
                                            cloudinaryProps={{
                                                aspectRatio: "8:5",
                                                crop: "fill",
                                            }}
                                            loading="eager"
                                        />
                                    </HoverZoom>
                                    <figcaption>
                                        <RichTextContent
                                            richText={slide.value.caption}
                                        />
                                    </figcaption>
                                </figure>
                            </Slide>
                        ))}
                    </Slider>
                </CarouselProvider>
            </div>
        </section>
    );
}
