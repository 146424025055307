import { standardBreakpointBuilder } from "components/Flexihero";
import {
    HeroBreakpointProps,
    HeroBreakpointSettings,
    HeroSetGroupProps,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { HeroSet } from "components/Flexihero/HeroSet";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import { StreamfieldBlock } from "interfaces";

import {
    BackgroundColorChoice,
    CmsHero,
    HeroMedia,
    heroColorSettings,
} from ".";

import heroStyles from "./hero-blocks.module.scss";

export interface NotchHeroCmsData extends CmsHero {
    notch_placement: "top" | "bottom";
    content_placement: "left" | "right";
    content_background_color: BackgroundColorChoice;
}

export type NotchHeroBlock = StreamfieldBlock<"notch_hero", NotchHeroCmsData>;

const notchHeroBaseSettings: HeroBreakpointSettings = {
    heroPlacement: "container",
    heroDirection: "row",
    mediaPlacement: "inline",
    contentWidth: "half",
    contentWidthCustom: "",
    contentAlignSecondary: "middle",
    textColor: "inherit",
    verticalMargin: "zero",
    backgroundColor: "transparent",
    backgroundColorPlacement: "content",
    contentAlignPrimary: "stretch",
    mediaAspectRatio: "stretch",
    minHeight: 400,
    textAlignment: "left",
};

const notchHeroSet = (
    content: HeroBreakpointProps,
    settings: HeroBreakpointSettings,
) =>
    standardBreakpointBuilder({
        desktop: content,
        tablet: {
            ...content,
            settings,
        },
        mobile: {
            ...content,
            settings: {
                ...settings,
                heroDirection: "column",
                contentWidth: "full",
                contentAlignSecondary: "right/bottom",
            },
        },
    });

export const NotchHero = (props: NotchHeroCmsData) => {
    const contentAlignPrimary =
        props.notch_placement === "top" ? "right/bottom" : "left/top";
    const contentAlignSecondary =
        props.content_placement === "left" ? "left/top" : "right/bottom";
    const colorSettings = heroColorSettings(props.content_background_color);
    const heroPlacement = useBlockWidth() === "full" ? "full" : "container";

    const desktopSettings: HeroBreakpointSettings = {
        ...notchHeroBaseSettings,
        heroPlacement,
        contentAlignPrimary,
        contentAlignSecondary,
        ...colorSettings,
    };

    const content: HeroBreakpointProps = {
        /** Content is being passed through, no modification in this component */
        content: props.content || "",
        /** Notch hero images work the same from hero to hero */
        media: (
            <HeroMedia
                media={props.media}
                imageProps={{
                    cldSrcSet: [620, 1240, 1860],
                    sizes: "(max-width: 767px) 100vw, (min-width: 768px) 50vw, (min-width: 1440px) 620px",
                    cloudinaryProps: {
                        aspectRatio: "8:5",
                        crop: "fill",
                    },
                    decorative: true,
                }}
                videoProps={{
                    autoPlay: false,
                    muted: false,
                    control: { visibility: "hover", placement: "center" },
                }}
            />
        ),
        /** Notch hero styles work the same from hero to hero */
        styles: {
            outerClassName: `${heroStyles.notchHero} al-notch-hero`,
            mediaWrapperClassName: heroStyles.notchMediaWrapper,
            contentWrapperClassName: heroStyles.notchWrapper,
            contentClassName: heroStyles.content,
        },
        /** Start here, override later */
        settings: desktopSettings,
    };
    const set: HeroSetGroupProps = notchHeroSet(content, desktopSettings);

    return <HeroSet {...set} />;
};
