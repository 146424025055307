import { Accordion, AccordionBlock } from "components/Accordion";
import {
    CarouselWithBackground,
    CarouselWithBackgroundBlock,
} from "components/CarouselWithBackground";
import { CrossSell, CrossSellBlock } from "components/CrossSell";
import {
    FullWidthCarousel,
    FullWidthCarouselBlock,
} from "components/FullWidthCarousel";
import {
    HardcodedForms,
    HardcodedFormsBlock,
    NewContactHardCodedForms,
    NewContactHardcodedFormsBlock,
} from "components/HardcodedForms";
import {
    FullWidthImageHero,
    FullWidthImageHeroBlock,
} from "components/HeroBlocks/FullWidthImage";
import {
    ImageBackgroundHero,
    ImageBackgroundHeroBlock,
} from "components/HeroBlocks/ImageBackgroundHero";
import { NotchHero, NotchHeroBlock } from "components/HeroBlocks/NotchHero";
import { SplitHero, SplitHeroBlock } from "components/HeroBlocks/SplitHero";
import {
    VerticalMediaBlock,
    VerticalMediaHero,
} from "components/HeroBlocks/VerticalMediaHero";
import { ImageTiles, ImageTilesBlock } from "components/ImageTiles";
import {
    LandscapeImageTiles,
    LandscapeImageTilesBlock,
} from "components/LandscapeImageTiles";
import {
    AlignedRichText,
    AlignedRichTextBlock,
    RichTextColumns,
    RichTextColumnsBlock,
} from "components/RichText";
import Spacer, { SpacerBlock } from "components/Spacer";
import { Testimonial, TestimonialBlock } from "components/Testimonials";
import { TileCarousel, TileCarouselBlock } from "components/TileCarousel";
import { Tiles, TilesBlock } from "components/Tiles";

export type Props = {
    block: ToolboxBlock;
    /**
     * Chasing "largest contentful paint" performance gains by eagerly loading
     * above-the-fold images
     */
    isFirstBlock: boolean;
};

export type ToolboxBlock =
    | FullWidthCarouselBlock
    | FullWidthImageHeroBlock
    | ImageTilesBlock
    | NotchHeroBlock
    | TestimonialBlock
    | TileCarouselBlock
    | SplitHeroBlock
    | ImageBackgroundHeroBlock
    | SpacerBlock
    | AccordionBlock
    | CarouselWithBackgroundBlock
    | CrossSellBlock
    | AlignedRichTextBlock
    | RichTextColumnsBlock
    | TilesBlock
    | LandscapeImageTilesBlock
    | HardcodedFormsBlock
    | NewContactHardcodedFormsBlock
    | VerticalMediaBlock;

export const Toolbox: React.FC<Props> = ({ block, isFirstBlock = false }) => {
    switch (block.type) {
        case "notch_hero":
            return <NotchHero {...block.value} />;
        case "split_hero":
            return <SplitHero {...block.value} />;
        case "image_background_hero":
            return (
                <ImageBackgroundHero
                    {...block.value}
                    isFirstBlock={isFirstBlock}
                />
            );
        case "full_width_image":
            return <FullWidthImageHero {...block.value} />;
        case "tile_carousel":
            return <TileCarousel {...block.value} />;
        case "image_tiles":
            return <ImageTiles {...block.value} />;
        case "spacer":
            return <Spacer {...block.value} />;
        case "testimonial":
            return <Testimonial {...block.value} />;
        case "accordion":
            return <Accordion {...block.value} />;
        case "carousel_with_background":
            return <CarouselWithBackground {...block.value} />;
        case "cross_sell":
            return <CrossSell {...block.value} />;
        case "full_width_carousel":
            return <FullWidthCarousel key={block.id} {...block.value} />;
        case "aligned_rich_text":
            return <AlignedRichText key={block.id} {...block.value} />;
        case "rich_text_columns":
            return <RichTextColumns key={block.id} {...block.value} />;
        case "tiles":
            return <Tiles key={block.id} {...block.value} />;
        case "landscape_image_tiles":
            return <LandscapeImageTiles key={block.id} {...block.value} />;
        case "hardcoded_form":
            return <HardcodedForms key={block.id} {...block.value} />;
        case "new_contact_hardcoded_form":
            return <NewContactHardCodedForms key={block.id} {...block.value} />;
        case "vertical_media":
            return <VerticalMediaHero key={block.id} {...block.value} />;
    }
    console.error(
        `Failed to find appropriate component to render module '${
            (block as any).type
        }'.`,
        "Usually this means the backend sent a module that isn't implemented in the frontend.",
    );
    return null;
};

/**
 * Convenience component to render a streamfield that supports all toolbox blocks
 * @param props
 */
export function ToolboxStreamfield({
    blocks,
    firstBlock = false,
}: {
    blocks: ToolboxBlock[];
    /** Whether the first block should be marked as such - some
     * blocks need this for correct margin on pages without headings */
    firstBlock?: boolean;
}) {
    return (
        <>
            {blocks.map((block, i) => (
                <Toolbox
                    key={block.id}
                    block={block}
                    isFirstBlock={firstBlock && i === 0}
                />
            ))}
        </>
    );
}
