import { standardBreakpointBuilder } from "components/Flexihero";
import {
    HeroBreakpointSettings,
    HeroPlacement,
    TextAlignment,
    TextColor,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { HeroSet } from "components/Flexihero/HeroSet";
import { StreamfieldBlock } from "interfaces";

import {
    BackgroundColorChoice,
    CmsHero,
    CmsMedia,
    HeroMedia,
    heroColorSettings,
} from ".";

import heroStyles from "./hero-blocks.module.scss";

export interface ImageBackgroundHeroCmsData extends CmsHero {
    /** "auto" is for situations where the the CMS user didn't pick an image */
    text_color: "auto" | TextColor;
    content_placement: "left" | "right" | "center";
    text_alignment: TextAlignment;
    background_color: BackgroundColorChoice;
    hero_placement?: HeroPlacement;
    content_align_secondary_mobile?: "left/top" | "middle" | "right/bottom";
    text_alignment_mobile?: "left" | "right" | "center";
    mobile_media?: CmsMedia;
}

export type ImageBackgroundHeroBlock = StreamfieldBlock<
    "image_background_hero",
    ImageBackgroundHeroCmsData
>;

interface Props extends ImageBackgroundHeroCmsData {
    isFirstBlock: boolean;
}

export const ImageBackgroundHero = (props: Props) => {
    const colorSettings = heroColorSettings(props.background_color);
    colorSettings.textColor =
        props.text_color === "auto"
            ? colorSettings.textColor
            : props.text_color;
    const contentAlignSecondary =
        props.content_placement === "left"
            ? "left/top"
            : props.content_placement === "center"
              ? "middle"
              : "right/bottom";

    const contentAlignSecondaryMobile =
        props.content_align_secondary_mobile || "right/bottom";

    const baseSettings: HeroBreakpointSettings = {
        heroPlacement: props.hero_placement || "background-full",
        heroDirection: "row",
        mediaPlacement: "background",
        contentWidth: "half",
        contentWidthCustom: "",
        contentAlignPrimary: "middle",
        contentAlignSecondary,
        verticalMargin: "zero",
        backgroundColorPlacement: "full",
        mediaAspectRatio: "none",
        textAlignment: props.text_alignment,
        minHeight: 400,
        ...colorSettings,
    };

    const mobileBaseSettings: HeroBreakpointSettings = {
        ...baseSettings,
        minHeight: undefined,
        heroDirection: "column",
        mediaPlacement: "inline",
        mediaAspectRatio: "none",
        contentAlignPrimary: "middle",
        contentAlignSecondary: contentAlignSecondaryMobile,
        contentWidth: "full",
        textColor: "inherit",
        textAlignment: props.text_alignment_mobile || props.text_alignment,
        heroPlacement: "full",
    };

    const set = standardBreakpointBuilder({
        desktop: {
            content: props.content || "",
            media: (
                <HeroMedia
                    media={props.media}
                    imageProps={{
                        cldSrcSet: [638, 1275, 2550, 3825],
                        sizes: "100vw",
                        cloudinaryProps: {
                            aspectRatio: "85:16",
                            crop: "fill",
                        },
                        decorative: true,
                        loading: props.isFirstBlock ? "eager" : "lazy",
                    }}
                    videoProps={{
                        autoPlay: true,
                        respectPreferReducedMotion: true,
                        control: { visibility: "none" },
                    }}
                />
            ),
            settings: {
                ...baseSettings,
                minHeight: 400,
            },
            styles: { contentClassName: heroStyles.content },
        },
        mobile: {
            content: props.content || "",
            media: (
                <HeroMedia
                    media={
                        !!props.mobile_media?.image ||
                        !!props.mobile_media?.video
                            ? props.mobile_media
                            : props.media
                    }
                    imageProps={{
                        cldSrcSet: [638, 1275, 2550, 3825],
                        sizes: "100vw",
                        cloudinaryProps: {
                            aspectRatio: "8:5",
                            crop: "fill",
                        },
                        decorative: true,
                    }}
                    videoProps={{
                        autoPlay: false,
                        control: { visibility: "native" },
                    }}
                />
            ),
            settings: mobileBaseSettings,
            styles: { contentClassName: heroStyles.content },
        },
    });
    return <HeroSet {...set} />;
};
