import classNames from "classnames";
import Clickable from "components/Clickable";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { HoverZoom } from "components/HoverZoom";
import { RichTextContent } from "components/RichText";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import {
    ClickableTile,
    CmsCtaImage,
    CmsImage,
    ImageContent as ImageContentCmsData,
    StreamfieldBlock,
} from "interfaces";

import styles from "./tiles-block.module.scss";

type TileLayout = "twoColumn" | "threeColumn" | "fourColumn";

export interface TilesCmsData {
    header?: string;
    layout: TileLayout;
    tiles: TilesStreamfieldBlock[];
}

type TilesStreamfieldBlock =
    | StreamfieldBlock<"cms_image", CmsImage>
    | StreamfieldBlock<"cms_cta_image", CmsCtaImage>
    | StreamfieldBlock<"image_content", ImageContentCmsData>
    | StreamfieldBlock<"image_content_75", ImageContentCmsData>
    | StreamfieldBlock<"clickable_tile", ClickableTile>;

export type TilesBlock = StreamfieldBlock<"tiles", TilesCmsData>;

export const Tiles = (props: TilesCmsData) => {
    const width = useBlockWidth();
    return (
        <section
            className={classNames({
                [styles.root]: true,
                [styles.full]: width === "full",
            })}
        >
            {props.header && (
                <header
                    className={classNames({
                        [styles.header]: true,
                        [styles.headerPadded]: width === "padded",
                    })}
                >
                    <RichTextContent richText={props.header} />
                </header>
            )}
            <div
                className={classNames({
                    [styles.grid]: true,
                    [styles[props.layout]]: true,
                })}
            >
                {props.tiles.map((tile, index) => {
                    switch (tile.type) {
                        case "cms_image":
                            return (
                                <CmsResponsiveImage
                                    key={tile.id}
                                    cmsImage={tile.value}
                                    cldSrcSet={[200, 400, 800, 1200]}
                                    sizes="100vw"
                                    cloudinaryProps={{
                                        aspectRatio: "1:1",
                                        crop: "fill",
                                    }}
                                />
                            );
                        case "cms_cta_image":
                            return (
                                <Clickable
                                    key={tile.id}
                                    cmsLink={tile.value.link}
                                    className={styles.ctaImage}
                                >
                                    {tile.value.image_block && (
                                        <HoverZoom>
                                            <CmsResponsiveImage
                                                cmsImage={
                                                    tile.value.image_block
                                                }
                                                cldSrcSet={[150, 295, 590, 885]}
                                                sizes="(max-width: 767px) 50vw, (max-width: 1024px) 25vw, (min-width: 1024px) 295px"
                                                cloudinaryProps={{
                                                    aspectRatio: "1:1",
                                                    crop: "fill",
                                                }}
                                                decorative={true}
                                            />
                                        </HoverZoom>
                                    )}
                                    {tile.value.text}
                                </Clickable>
                            );
                        case "image_content":
                            return (
                                <ImageContent
                                    aspectRatio="1:1"
                                    layout={props.layout}
                                    key={tile.id}
                                    tile={tile.value}
                                    index={index}
                                />
                            );
                        case "image_content_75":
                            return (
                                <ImageContent
                                    aspectRatio="0.75"
                                    layout={props.layout}
                                    key={tile.id}
                                    tile={tile.value}
                                    index={index}
                                />
                            );
                        case "clickable_tile":
                            return (
                                <Clickable
                                    key={tile.id}
                                    cmsLink={tile.value.link.link}
                                    className={classNames({
                                        [styles.clickableTile]: true,
                                        [tile.value.background_style]:
                                            !!tile.value.background_style,
                                    })}
                                >
                                    <RichTextContent
                                        className={styles.richText}
                                        richText={tile.value.content}
                                    />
                                    <span className={styles.clickableTileLink}>
                                        {tile.value.link.text}
                                    </span>
                                </Clickable>
                            );
                    }
                })}
            </div>
        </section>
    );
};

interface ImageContentProps {
    tile: ImageContentCmsData;
    aspectRatio: "1:1" | "0.75";
    layout: TileLayout;
    index: number;
}

const ImageContent = ({
    tile,
    aspectRatio,
    layout,
    index,
}: ImageContentProps) => {
    let sizes = "100vw";
    let cldSrcSet = [200, 400, 800, 1200];
    const getWidth = (width: number) => [
        width,
        width * 2,
        width * 3,
        width * 4,
        width * 5,
    ];
    let columnCount = 1;

    if (layout === "fourColumn") {
        const width = 317;
        columnCount = 4;
        cldSrcSet = getWidth(width);
        sizes = `(max-width: 767px) 50vw, (max-width: 1024px) 33vw, (min-width: 1025px) 25px, (min-width: 1480px) ${width}px`;
    }
    if (layout === "threeColumn") {
        const width = 428;
        columnCount = 3;
        cldSrcSet = getWidth(width);
        sizes = `(max-width: 767px) 100vw, (max-width: 1024px) 50vw, (min-width: 1025px) 33vw, (min-width: 1480px) ${width}px`;
    }
    if (layout === "twoColumn") {
        const width = 655;
        columnCount = 2;
        cldSrcSet = getWidth(width);
        sizes = `(max-width: 767px) 100vw, (min-width: 768px) 50vw, (min-width: 1480px) ${width}px`;
    }

    const href = tile.document || tile.link.url;
    return !!href ? (
        <div
            className={classNames([
                styles.imageContent,
                styles.imageContentWithLink,
            ])}
        >
            <Clickable
                href={href}
                target={!!tile.document ? "_blank" : "_self"}
            >
                <CmsResponsiveImage
                    cmsImage={tile.image}
                    cldSrcSet={cldSrcSet}
                    sizes={sizes}
                    cloudinaryProps={{
                        aspectRatio,
                        crop: "fill",
                    }}
                    className={classNames({
                        [styles.imageContentImage]: true,
                        [styles.threeToFour]: aspectRatio === "0.75",
                    })}
                    loading={index < columnCount ? "eager" : "lazy"}
                />
            </Clickable>
            <RichTextContent richText={tile.content} />
        </div>
    ) : (
        <div className={styles.imageContent}>
            <CmsResponsiveImage
                cmsImage={tile.image}
                cldSrcSet={cldSrcSet}
                sizes={sizes}
                cloudinaryProps={{ aspectRatio, crop: "fill" }}
                className={styles.imageContentImage}
                loading={index < columnCount ? "eager" : "lazy"}
            />
            <RichTextContent richText={tile.content} />
        </div>
    );
};
