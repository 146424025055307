import classNames from "classnames";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import {
    CarouselDots,
    NextSlideButton,
    PreviousSlideButton,
} from "components/GenericCarousel";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import { CmsImage, StreamfieldBlock } from "interfaces";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import styles from "./carousel-with-background.module.scss";

type GallerySlide = {
    image: CmsImage;
    caption: string;
};

export type CarouselWithBackgroundCmsData = {
    gallery: Array<StreamfieldBlock<"slide", GallerySlide>>;
};

export function CarouselWithBackground(props: CarouselWithBackgroundCmsData) {
    const width = useBlockWidth();
    return (
        <section className={styles.wrapper}>
            <div
                className={classNames(styles.carouselWithBackground, {
                    [styles.onlyOneSlide]: props.gallery.length <= 1,
                    [styles.full]: width === "full",
                })}
            >
                <CarouselProvider
                    naturalSlideHeight={479}
                    naturalSlideWidth={767}
                    infinite={true}
                    isIntrinsicHeight={true}
                    totalSlides={props.gallery.length}
                >
                    <PreviousSlideButton className={styles.back} />
                    <NextSlideButton className={styles.next} />
                    <CarouselDots dotGroupClass={styles.dotGroup} />
                    <Slider trayTag="ul">
                        {props.gallery.map((slide, index) => (
                            <Slide
                                key={slide.id}
                                index={index}
                                className={styles.slide}
                                tag="li"
                            >
                                <figure>
                                    <CmsResponsiveImage
                                        cmsImage={slide.value.image}
                                        sizes="(max-width: 767px) 80vw, (min-width: 767) 60vw"
                                        cldSrcSet={[600, 1200]}
                                        cloudinaryProps={{
                                            aspectRatio: "8:5",
                                            crop: "fill",
                                        }}
                                        loading="eager"
                                    />
                                    <figcaption
                                        dangerouslySetInnerHTML={{
                                            __html: slide.value.caption,
                                        }}
                                    ></figcaption>
                                </figure>
                            </Slide>
                        ))}
                    </Slider>
                </CarouselProvider>
            </div>
        </section>
    );
}

export type CarouselWithBackgroundBlock = StreamfieldBlock<
    "carousel_with_background",
    CarouselWithBackgroundCmsData
>;
