import {
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Accordion as ReachAccordion,
} from "@reach/accordion";
import "@reach/accordion/styles.css";
import classNames from "classnames";
import {
    AlignedRichText,
    AlignedRichTextCmsData,
    RichTextContent,
} from "components/RichText";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import { StreamfieldBlock } from "interfaces";

import Minus from "../../public/svg/minus.svg";
import Plus from "../../public/svg/plus.svg";

import styles from "./accordion.module.scss";

type AccordionRow = StreamfieldBlock<"row", { title: string; body: string }>;

export interface AccordionCmsData {
    header_content: AlignedRichTextCmsData;
    rows: Array<AccordionRow>;
}

export type AccordionProps = AccordionCmsData;

export type AccordionBlock = StreamfieldBlock<"accordion", AccordionCmsData>;

export const Accordion = ({ header_content, rows }: AccordionCmsData) => {
    const width = useBlockWidth();
    return (
        <section
            className={classNames({
                [styles.accordion]: true,
                [styles.padded]: width === "padded",
                [styles.full]: width === "full",
            })}
        >
            <header>
                <AlignedRichText
                    alignment={header_content.alignment}
                    content={header_content.content}
                    background_style={header_content.background_style}
                    isSubBlock={true}
                />
            </header>
            <AccordionRows rows={rows} />
        </section>
    );
};

export const AccordionRows = ({ rows }: { rows: AccordionRow[] }) => (
    <ReachAccordion collapsible={true} multiple={true} className={styles.list}>
        {rows.map(({ id, value }) => (
            <AccordionItem key={id} className={styles.listItem}>
                <AccordionHeading content={value.title} />
                <AccordionPanel className={styles.panel}>
                    <RichTextContent richText={value.body} />
                </AccordionPanel>
            </AccordionItem>
        ))}
    </ReachAccordion>
);

interface AccordionHeadingProps {
    headingTag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    content: string;
}

const AccordionHeading = ({
    headingTag: HeadingTag = "h1",
    content,
}: AccordionHeadingProps) => {
    return (
        <HeadingTag className={styles.heading}>
            <AccordionButton className={`${styles.button}  al-accordion__item`}>
                {content}
                <Plus className={styles.plusIcon} />
                <Minus className={styles.minusIcon} />
            </AccordionButton>
        </HeadingTag>
    );
};
