import classNames from "classnames";
import Clickable from "components/Clickable";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import {
    CarouselDots,
    NextSlideButton,
    PreviousSlideButton,
} from "components/GenericCarousel";
import { HoverZoom } from "components/HoverZoom";
import { RichTextContent } from "components/RichText";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import { useVisibleSlidesByBreakpoint } from "helpers/utils";
import { CmsCtaImage, StreamfieldBlock } from "interfaces";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import styles from "./tile-carousel.module.scss";

export interface TileCarouselCmsData {
    intro_copy: string;
    cta_list: StreamfieldBlock<"cta_image", CmsCtaImage>[];
}

export type TileCarouselBlock = StreamfieldBlock<
    "tile_carousel",
    TileCarouselCmsData
>;

export const TileCarousel = ({ intro_copy, cta_list }: TileCarouselCmsData) => {
    const visibleSlides = useVisibleSlidesByBreakpoint(3, 1, 2);
    const width = useBlockWidth();

    return (
        <section
            className={classNames({
                [styles.imageTiles]: true,
                [styles.full]: width === "full",
            })}
        >
            <header>
                <RichTextContent richText={intro_copy} />
            </header>
            <CarouselProvider
                naturalSlideWidth={295}
                naturalSlideHeight={545}
                infinite={false}
                isIntrinsicHeight={true}
                visibleSlides={visibleSlides}
                totalSlides={cta_list.length}
                className={styles.carousel}
                step={visibleSlides}
            >
                <Slider className={styles.slider} trayTag="ul">
                    {cta_list.map((listItem, index) => (
                        <Slide
                            key={listItem.id}
                            index={index}
                            className={styles.slide}
                            tag="li"
                        >
                            <Clickable
                                cmsLink={listItem.value.link}
                                className={styles.clickable}
                            >
                                {listItem.value.image_block && (
                                    <HoverZoom>
                                        <CmsResponsiveImage
                                            cmsImage={
                                                listItem.value.image_block
                                            }
                                            cldSrcSet={[150, 295, 590, 885]}
                                            sizes="(max-width: 767px) 50vw, (max-width: 1024px) 25vw, (min-width: 1024px) 295px"
                                            cloudinaryProps={{
                                                aspectRatio: "1:1",
                                                crop: "fill",
                                            }}
                                            decorative={true}
                                        />
                                    </HoverZoom>
                                )}
                                {listItem.value.text}
                            </Clickable>
                        </Slide>
                    ))}
                </Slider>
                <PreviousSlideButton
                    className={`${styles.buttonPrevious} al-tile-carousel__prev`}
                />
                <NextSlideButton
                    className={`${styles.buttonNext} al-tile-carousel__next`}
                />
                <CarouselDots
                    dotGroupClass={`${styles.dotGroup}  al-tile-carousel__dots`}
                />
            </CarouselProvider>
        </section>
    );
};
